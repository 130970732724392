import React  from 'react'
import GameContainer from './components/GameContainer';


function App() {
  return (
    <div className="App">
      <h1 className='app__title'>Countries Quiz</h1>


      <GameContainer />

    </div>
  );
}

export default App;
